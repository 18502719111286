<template>
  <div>
    <b-card
        no-body
        :class="{'open': visible}"
        @mouseenter="collapseOpen"
        @mouseleave="collapseClose"
    >
      <b-card-header

          :class="{'collapsed': !visible}"
          :aria-expanded="visible ? 'true' : 'false'"
          :aria-controls="collapseItemID"
          role="tab"
          data-toggle="collapse"
          @click="updateVisible(!visible)"
      >
        <slot name="header">
          <div class="d-flex justify-content-between w-100">
            <div class="lead collapse-title">{{ title }}</div>
            <div class="lead collapse-title">
              <b-badge :variant="badgeColor">{{ score }} / {{ maxScore }}</b-badge>
            </div>
          </div>
        </slot>
      </b-card-header>

      <b-collapse
          :id="collapseItemID"
          v-model="visible"
          :accordion="accordion"
          role="tabpanel"
      >
        <b-card-body>
          <slot />
        </b-card-body>
      </b-collapse>
    </b-card>

<!--    <b-card no-body v-else>
      <b-card-header style="cursor: auto">
        <slot name="header">
          <div class="d-flex justify-content-between w-100">
            <div class="lead collapse-title">{{ title }}</div>
            <div class="lead collapse-title">
              <b-badge :variant="badgeColor">{{ score }} / {{ maxScore }}</b-badge>
            </div>
          </div>
        </slot>
      </b-card-header>
    </b-card>-->
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse, BBadge
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BBadge,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    score: {
      type: String | Number,
      required: true
    },
    maxScore: {
      type: String | Number,
      required: true
    },
    isPass: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
    badgeColor() {
      return this.isPass ? 'success' : 'danger'
    }
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
}
</script>
