import { render, staticRenderFns } from "./WorkViewDetail.vue?vue&type=template&id=79d18308&scoped=true&"
import script from "./WorkViewDetail.vue?vue&type=script&lang=js&"
export * from "./WorkViewDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d18308",
  null
  
)

export default component.exports