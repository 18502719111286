<template>
  <div>
    <work-view-detail :result="result" :is-show-loading="isShowLoading"/>
    <work-view-safety-check :work-id="id"/>

    <b-overlay :show="isShowLoading">
      <validation-observer ref="validateForm" #default="{invalid}">
        <b-form @submit.prevent="saveData">
          <b-card :title="$t('work.headerExtend')">
            <div class="text-right">
              <b-button variant="primary" class="mb-1" @click="addWorkDay">{{ $t('work.btnAddWorkingDay') }}</b-button>
            </div>

            <div class="multiple-row" v-for="(row, key) in workingDayLists" :key="'workDay-' + key">
              <b-row>
                <b-col>
                  <h5 class="mb-1">#{{ row.workNum }}</h5>
                </b-col>

                <b-col class="text-right">
                  <b-button variant="danger" size="sm" @click="deleteWorkDay(key)" v-if="workingDayLists.length > 1">
                    {{ $t('general.btnDelete') }}
                  </b-button>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <b-form-group :label="$t('work.workingDayType')">
                    <b-form-radio-group v-model="row.workDayType">
                      <b-form-radio value="1">{{ $t('work.radioWorkingDay') }}</b-form-radio>
                      <b-form-radio value="2">{{ $t('work.radioHoliday') }}</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group :label="$t('work.workingDay') + '*'">
                    <validation-provider #default="{ errors }" :name="$t('work.workingDay')" rules="required">
                      <flat-pickr v-model="row.workDate" class="form-control" :config="datePickerConfig"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group :label="$t('work.timeOfWork')">
                    <flat-pickr v-model="row.workStartTime" class="form-control" :config="$store.state.main.timePickerConfig"/>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group :label="$t('work.to')">
                    <flat-pickr v-model="row.workEndTime" class="form-control" :config="$store.state.main.timePickerConfig"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <b-row>
              <b-col md="12">
                <b-form-group :label="$t('work.extendReason') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('work.extendReason')" rules="required">
                    <b-form-input v-model="extendReason" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

          </b-card>

          <b-row v-if="!isShowLoading">
            <b-col class="text-center">
              <b-button type="submit" variant="success" class="my-1" :disabled="isBtnDisabled">
                <b-spinner small v-if="isBtnDisabled"/>
                <feather-icon icon="SaveIcon" v-else/>
                {{ $t('general.btnSubmit') }}
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BButton, BForm, BFormRadioGroup, BOverlay, BFormRadio} from 'bootstrap-vue'
import {required} from '@validations'
import formMixins from "@/mixins/formMixins"
import WorkViewDetail from "@/components/work/WorkViewDetail"
import WorkViewSafetyCheck from "@/components/work/WorkViewSafetyCheck"
import {WorkService, FileStorageService} from "@/services"
import masterMixins from "@/mixins/masterMixins"

const workService = new WorkService()
const fileStorageService = new FileStorageService()

export default {
  name: 'WorkFromExtend',
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormRadioGroup,
    BOverlay,
    BFormRadio,
    WorkViewDetail,
    WorkViewSafetyCheck,
  },
  mixins: [masterMixins, formMixins],
  async created() {
    this.id = this.$route.params.id
    await this.getData()
    this.addWorkDay()
    this.enabledCheckBackButton()
  },
  destroyed() {
    let config = this.$store.state.main.datePickerConfig
    config.mode = 'single'
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    id: '',
    result: {},
    workingDayLists: [],
    extendReason: '',
  }),
  computed: {
    datePickerConfig() {
      let config = this.$store.state.main.datePickerConfig
      config.mode = 'range'
      return config
    }
  },
  methods: {
    async getData() {
      let queryResult = await workService.getData(this.id)
      if (queryResult.isSuccess) {
        let {data} = queryResult
        let {result} = data
        this.result = result

        this.isShowLoading = false
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
        await this.$router.push({name: 'pageWork'})
      }
    },
    saveData() {
      this.$bvModal
          .msgBoxConfirm(this.$t('work.extendConfirm'), {
            title: this.$t('general.deleteTitle'),
            okVariant: 'primary',
            okTitle: this.$t('general.deleteConfirm'),
            cancelTitle: this.$t('general.deleteCancel'),
            cancelVariant: 'outline-secondary',
          })
          .then(async value => {
            if (value) {
              this.$refs.validateForm.validate().then(async success => {
                if (success) {
                  let actionData = {
                    extendReason: this.extendReason,
                    workingDayLists: this.workingDayLists,
                  }

                  workService.extendRequest(this.id, actionData)
                      .then(({isSuccess, data}) => {
                        this.isBtnDisabled = false
                        if (isSuccess) {
                          this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
                          this.$router.replace({name: 'pageWork'})
                        } else {
                          this.$store.commit('main/setToastError', data)
                        }
                      })
                }
              })
            }
          })
    },
    addWorkDay() {
      this.workingDayLists.push({
        workDayId: '',
        workDayType: '1',
        workDate: '',
        workStartTime: '',
        workEndTime: '',
        workNum: this.workingDayLists.length + 1,
      })
    },
    deleteWorkDay(row) {
      this.workingDayLists.splice(row, 1)
      this.workingDayLists = this.workingDayLists.map((data, key) => ({...data, workNum: key + 1}))
    },
  }
}
</script>

<style lang="scss">
</style>
