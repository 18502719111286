<template>
  <div>
    <b-overlay :show="showLoading">
      <b-card-actions :title="$t('work.headerSafetyCheck')" action-collapse :collapsed="cardCollapsed">
        <app-collapse accordion type="margin" v-for="(row, key) in results" :key="key">

          <safety-collapse-item :title="row | showTitle" :score="row | showPassScore" :max-score="row | showTotalScore" :is-pass="row | isPass">
<!--            <h5>{{ $t('work.safetyNotPassTopic') }}</h5>-->

            <div class="media-list media-bordered"
                 v-for="item in row.safetyLists" :key="`fail${item.checkId}`"
            >
              <b-media vertical-align="center">
                <template #aside>
                  <thumbnail-file :src="item.safetyAttach ? item.safetyAttach : noImage" />
                </template>
                <h5 class="mt-1">
                  {{ item.safetyName }}
                  <b-badge :variant="item.safetyStatus === 'Y' ? 'success' : 'danger'">{{ item.safetyStatus === 'Y' ? 'Pass' : 'Not pass' }}</b-badge>
                </h5>
                <b-card-text>
                  {{ item.safetyDescription }}
                </b-card-text>
              </b-media>

            </div>
          </safety-collapse-item>
        </app-collapse>
      </b-card-actions>
    </b-overlay>
  </div>
</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay, BMedia, BImg, BBadge} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import SafetyCollapseItem from '@/components/work/SafetyCollapseItem'

import {WorkService} from "@/services"

const workService = new WorkService()

export default {
  name: "WorkViewSafetyCheck",
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BMedia,
    BImg,
    BBadge,
    BCardActions,
    AppCollapse,
    SafetyCollapseItem,
  },
  props: {
    workId: {
      required: true
    },
    cardCollapsed: {
      Type: Boolean,
      default: false
    }
  },
  created() {
    this.listData()
  },
  data: () => ({
    results: [],
    noImage: require('@/assets/images/no-image.jpeg')
  }),
  watch: {
    workId() {
      this.listData()
    }
  },
  computed: {
    showLoading() {
      return this.isShowLoading
    },
  },
  filters: {
    showTitle(result) {
      if(result.safetyCheckBy === '1') {
        return `${result.safetyCheckByName} ตรวจสอบ${result.safetyCheckTypeName} ${result.createDate}`
      } else {
        return `${result.safetyCheckByName} ตรวจสอบ ${result.createDate}`
      }
    },
    showPassScore(result) {
      return result.safetyLists.filter(data => data.safetyStatus === 'Y').length
    },
    showTotalScore(result) {
      return result.safetyLists.length
    },
    isPass(result) {
      return result.safetyLists.filter(data => data.safetyStatus === 'Y').length === result.safetyLists.length
    },
  },
  methods: {
    async listData() {
      let queryResult = await workService.getSafetyCheck(this.workId)
      if (queryResult.isSuccess) {
        let {data} = queryResult
        let {results} = data
        this.results = results

        console.log('row', this.results.length)
        this.$emit('total-safety-row', this.results.length)

        this.isShowLoading = false
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.media-list .media {
  padding-left: 0;
}
</style>