<template>
  <div>
    <b-overlay :show="showLoading">
      <b-card>
        <b-card-header style="padding: 0.5rem 0;">
          <b-badge pill :variant="badgeVariant(result.workStep)">{{ workStatusName }}</b-badge>
          <b-card-title class="text-primary font-weight-bolder">
            WP No. {{ result.workCode }}
            <feather-icon icon="PrinterIcon" style="cursor: pointer;" @click="printWorkCard"/>
          </b-card-title>
        </b-card-header>

        <b-card-title class="mt-2 mb-3">
          {{ $t('work.headerDescriptionOfWork') }}
        </b-card-title>

        <b-row>
          <b-col md="6" v-if="userData && userData.userCategory === 1">
            <b-form-group :label="$t('work.company')">
              <b-form-input v-model="result.companyName" plaintext/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group :label="$t('work.workType')">
              <b-form-input v-model="result.workTypeName" plaintext/>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group :label="$t('work.workScope')">
              <b-form-input v-model="result.workScope" plaintext/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group :label="$t('work.department')">
              <b-form-input v-model="result.departmentName" plaintext/>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group :label="$t('work.location')">
              <b-form-input v-model="result.locationName" plaintext/>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay, BCardHeader, BCardTitle, BBadge} from 'bootstrap-vue'
import tableMixins from "@/mixins/tableMixins"

export default {
  name: "WorkViewDetail",
  mixins: [tableMixins],
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BCardHeader,
    BCardTitle,
    BBadge,
  },
  props: {
    result: {
      type: Object,
      required: true
    },
    isShowLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    userData: JSON.parse(localStorage.getItem('userData')),
  }),
  computed: {
    showLoading() {
      return this.isShowLoading
    },
    workStatusName() {
      if(this.result && this.result.workStepResult) {
        return this.switchLanguage(this.result.workStepResult.groupStatusTh, this.result.workStepResult.groupStatusEn)
      }
    }
  },
  methods: {
    printWorkCard() {
      window.location.href=`${process.env.VUE_APP_URL_API}work-permit-card?workCode=${this.result.workCode}&workId=${this.result.workId}`
    }
  }
}
</script>

<style scoped>

</style>